import api from '@admin/api/service';

export default {
  methods: {
    async fetchGetPerformanceAds() {
      let response = {};

      try {
        const { data } = await api.get(
          '/singgahsini/owner-dashboard/performance/ads'
        );
        response = data.data;
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },
  },
};
