export default {
  methods: {
    async handleGetPerformanceAds() {
      const response = await this.fetchGetPerformanceAds();

      if (response.hasError) {
        const error =
          response.data.issue?.message ||
          response.data.message ||
          'Terjadi galat, silahkan coba beberapa saat lagi.';
        this.$toast.show(error);
      } else {
        this.responseData = response;
        const total = response.total_ads_performances || {};
        this.tabs = this.tabs.map(tab => ({
          ...tab,
          total: total[tab.id] || null,
        }));

        this.createChartLabel();
      }
    },
  },
};
