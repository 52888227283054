<template>
  <div class="page-property-detail-performance">
    <div class="filter mb-24" v-if="isDisplayed">
      <bg-select
        class="children"
        data-testid="filter-select-room"
        v-model="filter.roomType.value"
        :options="filter.roomType.options"
      />
      <bg-datepicker
        class="children"
        minimum-view="month"
        format="MMM yyyy"
        fixed-position="bottom-right"
        data-testid="filter-select-month"
        v-model="filter.date"
      />
      <bg-button class="children" data-testid="filter-terapkan-btn" disabled>
        Terapkan
      </bg-button>
    </div>

    <section>
      <bg-text size="heading-4" class="mb-32">
        {{ selectedTab.name }}
      </bg-text>
      <bg-card class="tab-chart p-0" v-if="isLoading.chart">
        <div class="left-content">
          <bg-skeleton width="100%" height="50px" class="mb-8" />
          <bg-skeleton width="100%" height="50px" class="mb-8" />
          <bg-skeleton width="100%" height="50px" />
        </div>
        <div class="right-content">
          <bg-skeleton width="100%" height="50px" class="mb-16" />
          <bg-skeleton width="100%" height="30px" class="mb-16" />
          <bg-skeleton width="100%" height="300px" />
        </div>
      </bg-card>
      <bg-card class="tab-chart p-0" data-testid="performance-tabs">
        <div class="left-content">
          <tab-button :data="tabs" @handle-click="handleTabClick" />
        </div>
        <div class="right-content">
          <bg-text size="heading-4" class="mb-16">
            {{ selectedTab.title }}
          </bg-text>
          <bg-text size="body-2" class="mb-16">
            {{ selectedTab.additionalText }}
          </bg-text>

          <template v-if="isPromoTab"> promoooo </template>
          <template v-else>
            <bg-card v-if="!isLoading.chart">
              <div
                class="flex justify-space-between"
                data-testid="content-summary"
              >
                <bg-text size="body-2"> Total {{ selectedTab.title }} </bg-text>
                <bg-text size="body-1">{{ selectedTab.total }}</bg-text>
              </div>
            </bg-card>
            <chart chartId="main_chart" :data="chartData" type="bar" />
          </template>
        </div>
      </bg-card>
    </section>
  </div>
</template>

<script>
import {
  BgSelect,
  BgButton,
  BgDatepicker,
  BgText,
  BgCard,
  BgSkeleton,
} from 'bangul-vue';
import dayjs from 'dayjs';
import fetchApi from './mixins/fetchApi.js';
import handleApi from './mixins/handleApi.js';
import tabId from './constants/performanceTabId.js';

export default {
  name: 'PropertyDetailPerformance',

  components: {
    BgSelect,
    BgButton,
    BgDatepicker,
    BgText,
    BgCard,
    BgSkeleton,
    TabButton: () => import('@/components/molecules/TabButton/desktop'),
    Chart: () => import('@molecules/Chart'),
  },

  props: {
    // Temporary props, can be deleted later when the Filter API is ready
    isDisplayed: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [fetchApi, handleApi],

  data() {
    return {
      tabs: [
        {
          id: tabId.ADS_VISITED,
          title: 'Kunjungan Iklan',
          total: null,
          additionalText:
            'Kunjungan iklan adalah berapa kali iklan kos diklik dan dilihat oleh pencari kos.',
          active: true,
        },
        {
          id: tabId.ADS_KOS,
          title: 'Iklan Kost',
          total: null,
          additionalText:
            'Peminat kos berdasarkan jumlah pencari kos yang klik Favorit ❤️ di iklan kos.',
          active: false,
        },
        {
          id: tabId.INCOMING_CHAT,
          title: 'Chat Masuk',
          total: null,
          additionalText:
            'Chat masuk berdasarkan jumlah chat dari pencari kos yang diterima oleh kos.',
          active: false,
        },
        {
          id: tabId.SURVEY_SUBMISSION,
          title: 'Pengajuan Survey',
          total: null,
          additionalText:
            'Pengajuan survei adalah jumlah calon penyewa yang berminat untuk melakukan survei ke kos ini.',
          active: false,
        },
        {
          id: tabId.BOOKING_SUBMISSION,
          title: 'Pengajuan Sewa',
          total: null,
          additionalText:
            'Angka berdasarkan jumlah calon penyewa yang berminat dan mengajukan sewa di kos ini.',
          active: false,
        },
        {
          id: tabId.PAID_BOOKING_SUBMISSION,
          title: 'Pengajuan Sewa yang Terbayar',
          total: null,
          additionalText:
            'Jumlah calon penyewa yang mengajukan sewa dan melakukan pembayaran di kos.',
          active: false,
        },
        {
          id: tabId.PROMO,
          title: 'Promo',
          total: null,
          additionalText:
            'Daftar promo dan diskon yang dilakukan untuk promosi kos.',
          active: false,
        },
      ],
      filter: {
        roomType: {
          value: '',
          options: [
            {
              val: '1',
              label: 'test',
            },
            {
              val: '2',
              label: 'test 2',
            },
          ],
        },
        date: new Date(),
      },
      responseData: {},
      chartData: {
        labels: [],
        values: [],
      },
      selectedTab: {},
      isLoading: {
        chart: false,
      },
    };
  },

  computed: {
    isPromoTab() {
      return this.selectedTab.id === tabId.PROMO;
    },
  },

  async created() {
    this.isLoading.chart = true;
    await this.handleGetPerformanceAds();
    this.showChartData(this.tabs[0]);
    this.isLoading.chart = false;
  },

  methods: {
    handleTabClick(payload) {
      this.tabs = this.tabs.map(e => ({
        ...e,
        active: e.id === payload,
      }));

      const activeObj = this.tabs.find(e => e.id === payload);
      this.showChartData(activeObj);
    },
    createChartLabel() {
      if (this.responseData.period_type === 'weekly') {
        const period = this.responseData.ads_performances.map(
          e =>
            `${dayjs(e.start_period).format('DD MMM')} - ${dayjs(
              e.end_period
            ).format('DD MMM')}`
        );
        this.chartData.labels = period;
      } else {
        const period = this.responseData.ads_performances.map(
          e => `${dayjs(e.start_period).format('MMM')}`
        );
        this.chartData.labels = period;
      }
    },
    showChartData(obj) {
      this.isLoading.chart = true;
      const performanceAds = this.responseData.ads_performances || [];
      this.chartData.values = performanceAds.map(e => e[obj.id] || 0);

      this.selectedTab = { ...obj };
      this.isLoading.chart = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./PropertyDetailPerformance.scss" />
